import { Button, Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditableGrid from '../../Features/EditableGrid';
import { IWydanie, IWydanieDetail, wydanieDetailsColumns } from '../../Models/Wydanie';
import { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { completeWydanieApi, editWydanieDetailApi, getAllWydanieDetailsApi, removeWydanieDetailApi } from '../../Services/WydanieService';
import { AxiosError, AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { handleErros, handleSuccess } from '../../Helpers/AxiosCustomHeaders';
import { AppContext } from '../../App';
import ModalElement from '../../Features/Modal';
import AddWydanieDetailModal from './AddWydanieDetailModal';
import { OpenMagazynToWzDetailModal } from './OpenMagazynToWzDetailModal';
import PrintIcon from '@mui/icons-material/Print';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import { getUrlToPrintWzTemplate } from '../../Services/SupplyService';
import { Debounce } from '../../Helpers/ButtonDebouncing';
import AdditionalDataWydanieModal from './AdditionalDataWydanieModal';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { ZwrotColumn } from '../AdditionalColumns';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { pages } from '../../Features/AppBarAndLeftMenu';
import ReportWzHistoryPage, { paramNameWzHistory } from '../../Sites/ReportWzHistoryPage';
import { Link } from 'react-router-dom';
import { UserRole } from '../../Models/Jwt';
import { GridRowSelectionModel } from '@mui/x-data-grid';

interface OpenWydanieDetailsModalProps {
	doubleClickedWydanieRow: IWydanie;
  optionsTowary: any[];
  closeDetailsModalAction: Dispatch<void>;
}
export function OpenWydanieDetailsModal({ doubleClickedWydanieRow, optionsTowary, closeDetailsModalAction }: OpenWydanieDetailsModalProps) {
  const appContext = useContext(AppContext);
	const [total, setTotal] = useState<number>(0);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(true);
  const [loadingCompleteButton, setLoadingCompleteButton] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<IWydanieDetail[]>([]);
	const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  const [openAddDetailModal, setOpenAddDetailModal] = useState(false);
  const [openMagazynToWzDetailModal, setOpenMagazynToWzDetailModal] = useState(false);
  const [openAdditionalDataModal, setOpenAdditionalDataModal] = useState(false);
  const [doubleClickedWydanieDetailRow, setDoubleClickedWydanieDetailRow] = useState<IWydanieDetail | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

	const handleCloseDetailsModalAction = useCallback(() => {
    closeDetailsModalAction();
  }, [closeDetailsModalAction])

  const initialize = useCallback(async () => {
		setLoadingInitialization(true);
		await getAllWydanieDetailsApi(doubleClickedWydanieRow.id, Number(appContext?.rowsNumberPageModel), page).then(
			(response: AxiosResponse) => {
				setRows(response.data.results);
				setTotal(response.data.total);
				setReady(response.data.ready);
				setLoadingInitialization(false);
			},
			(error: AxiosError) => {
				handleErros(error, appContext);
				setLoadingInitialization(false);
			});
  }, [doubleClickedWydanieRow.id, page, appContext]);
	useEffect(() => { initialize(); }, [initialize, page]);

  const editAction = async (newRow: IWydanieDetail, oldRow: IWydanieDetail) : Promise<boolean> => {
    if (newRow.comesFromCountry === null || newRow.comesFromCountry === "" || newRow.comesFromCountry === undefined ||
      newRow.boxType === null || newRow.boxType === "" || newRow.boxType === undefined ||
      newRow.numberOfBoxes === null || newRow.numberOfBoxes === undefined
    ) {
      enqueueSnackbar('Proszę uzupełnić wszystkie pola.', { variant: 'info' });
      return false;
    } else {
      return await editWydanieDetailApi(
        newRow.id,
				newRow.comesFromCountry,
				newRow.boxType,
				newRow.numberOfBoxes)
        .then(
          (response: AxiosResponse) => {
            initialize();
            handleSuccess(response);
            return true;
          })
        .catch(
          (error: AxiosError) => {
            handleErros(error, appContext);
            return false;
          }
        );
    }
  };
	
  const deleteAction = async (row: IWydanieDetail) => {
    setLoadingInitialization(true);
    await removeWydanieDetailApi(row.id).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingInitialization(false);
        initialize();
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    );
  };
	
  const doubleClickWydanieDetailRowAction = async (row: IWydanieDetail) => {
		if (row.count === null) {
      enqueueSnackbar(`Towar ${row.towarName} nie posiada zdefiniowanej ilości sztuk w opakowaniu handlowym.`, { variant: 'info' });
		} else {
			setDoubleClickedWydanieDetailRow(row);
    	setOpenMagazynToWzDetailModal(true);
		}
  };

	const handleCompleteButton = async () => {
    setLoadingCompleteButton(true);
    await completeWydanieApi(doubleClickedWydanieRow.id).then(
      async (response: AxiosResponse) => {
				await Debounce(setLoadingCompleteButton, false);
        handleSuccess(response);
				handleCloseDetailsModalAction();
      },
      (error: any) => {
        handleErros(error, appContext);
        setLoadingCompleteButton(false);
      }
    )
  };
  
  const closeMagazynToWzDetailModalAction = () => {
    setOpenMagazynToWzDetailModal(false);
    initialize();
  };

	return (
		<><h1 style={{ margin: '0 0 20px 0' }}>Detale wydania zewnętrznego</h1>
			<span style={{ marginTop: "-28px" }}>{doubleClickedWydanieRow.odbiorca} {doubleClickedWydanieRow.number}</span>
			<Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1 }} style={{ marginTop: "-5px", marginBottom: "8px" }}>
				{(doubleClickedWydanieRow.completed === false) &&
				<Grid item xs={12} sm={2} md={3} lg={3}>
					<IconButton onClick={() => setOpenAddDetailModal(true)} disabled={false} className='customAddLoadingButton' color={"primary"}>
						<AddIcon sx={{ fontSize: "30px !important" }} />
					</IconButton>
				</Grid>}
				{(doubleClickedWydanieRow.completed === false) && ready &&
          doubleClickedWydanieRow.driverNumber !== null && doubleClickedWydanieRow.driverNumber !== undefined && doubleClickedWydanieRow.driverNumber !== "" &&
          doubleClickedWydanieRow.driverName !== null && doubleClickedWydanieRow.driverName !== undefined && doubleClickedWydanieRow.driverName !== "" &&
          doubleClickedWydanieRow.euroPalletCount !== null && doubleClickedWydanieRow.euroPalletCount !== undefined &&
          doubleClickedWydanieRow.normalPalletCount !== null && doubleClickedWydanieRow.normalPalletCount !== undefined ?
        <Grid item xs={12} sm={5} md={3} lg={3}>
          <LoadingButton sx={{ width: '100%', maxWidth: '250px' }}
            endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
            loading={loadingCompleteButton}
            loadingPosition="center"
            variant="contained"
            onClick={handleCompleteButton}>Zatwierdź
          </LoadingButton>
        </Grid> : <></>}
				{(doubleClickedWydanieRow.completed === false) &&
          (doubleClickedWydanieRow.driverNumber === null || doubleClickedWydanieRow.driverNumber === undefined || doubleClickedWydanieRow.driverNumber === "" ||
          doubleClickedWydanieRow.driverName === null || doubleClickedWydanieRow.driverName === undefined || doubleClickedWydanieRow.driverName === "" ||
          doubleClickedWydanieRow.euroPalletCount === null || doubleClickedWydanieRow.euroPalletCount === undefined ||
          doubleClickedWydanieRow.normalPalletCount === null || doubleClickedWydanieRow.normalPalletCount === undefined) ?
          <Grid item xs={12} sm={5} md={3} lg={3}>
          <LoadingButton sx={{ width: '100%', maxWidth: '250px' }}
            endIcon={<PostAddIcon style={{fontSize: '30px'}}/>}
            loading={loadingCompleteButton}
            loadingPosition="center"
            variant="contained"
            onClick={() => setOpenAdditionalDataModal(true)}>Uzupełnij dane
          </LoadingButton>
        </Grid> : <></>}
				{ready &&
        <Grid item xs={12} sm={(doubleClickedWydanieRow.completed === true) ? 5 : 6} md={(doubleClickedWydanieRow.completed === true) ? 3 : 4} lg={(doubleClickedWydanieRow.completed === true) ? 3 : 4}>
          <Button sx={{ width: '100%', maxWidth: (doubleClickedWydanieRow.completed === true) ? '250px' : '270px' }}
            endIcon={<PrintIcon style={{fontSize: '30px'}}/>}
            variant={"contained"}
            target='_blank'
            href={getUrlToPrintWzTemplate(doubleClickedWydanieRow.id)}
						>Drukuj{(doubleClickedWydanieRow.completed === true) ? '' : ' wersję roboczą'}
          </Button>
        </Grid>}
        {appContext?.userData?.userRole === UserRole.admin && doubleClickedWydanieRow.completed &&
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Link to={`${pages.find(x => (x.element as any).type.name === ReportWzHistoryPage.name)?.path}?${paramNameWzHistory}=${doubleClickedWydanieRow.id}`}>
            <Button sx={{ width: '100%', maxWidth: '250px'}}
              endIcon={<ScheduleSendIcon style={{fontSize: '30px'}}/>}
              variant={"contained"}
              >Zobacz historie WZ
            </Button>
          </Link>
        </Grid>}
			</Grid>
			<EditableGrid
				columns={wydanieDetailsColumns.concat(ZwrotColumn(null, null, false))}
				rows={rows}
				editAction={editAction}
				loading={loadingInitialization}
				setPaginationModel={paginationModelChange}
				paginationModel={{ pageSize: Number(appContext?.rowsNumberPageModel), page: page }}
				total={total}
				setQueryOptions={() => null}
				doubleClickRowAction={(row: IWydanieDetail) => {doubleClickWydanieDetailRowAction(row)}}
				deleteAction={deleteAction}
				hideDeleteAction={false}
				hideEditAction={false}
				hideActionColumn={doubleClickedWydanieRow.completed}
				checkboxSelection={false}
				disableRowSelectionOnClick={false}
				setRowsSelectionModel={(param: any) => { setRowSelectionModel(param); }}
				rowSelectionModel={rowSelectionModel}
				hideFooter={false}
				hideFooterSelectedRowCount={true}
				columnVisibilityModel={{}}
				setColumnVisibilityModel={() => null}
				columnGroupingModel={undefined}
				additionalStyles={undefined} />
			<ModalElement handleClose={() => setOpenAddDetailModal(false)} open={openAddDetailModal} centerCloseButton={false}
        additionalStyles={{width: '35% !important', minWidth: '500px !important', top: '15% !important'}}
				modalContent={
					<AddWydanieDetailModal wydanie={doubleClickedWydanieRow} optionsTowary={optionsTowary} setOpenAddDetailModal={(value) => { setOpenAddDetailModal(value); initialize(); }} />
				}
			/>
      <ModalElement handleClose={() => setOpenAdditionalDataModal(false)} open={openAdditionalDataModal} centerCloseButton={false}
        additionalStyles={{width: '40% !important', minWidth: '600px !important', top: '10% !important'}}
        modalContent={
          <AdditionalDataWydanieModal wydanie={doubleClickedWydanieRow} setOpenAdditionalDataWydanieModal={(value) => { setOpenAdditionalDataModal(value); }}
            />
          }
      />
			<ModalElement handleClose={() => { setOpenMagazynToWzDetailModal(false); initialize(); }} centerCloseButton={false} open={openMagazynToWzDetailModal} additionalStyles={{top: '15% !important'}}
				modalContent={
					<OpenMagazynToWzDetailModal doubleClickedWydanieDetailRow={doubleClickedWydanieDetailRow!}
            doubleClickedWydanieRow={doubleClickedWydanieRow} closeMagazynToWzDetailModalAction={closeMagazynToWzDetailModalAction} />
				}
			/>
		</>
	);
}