import axios from "axios";
import { CustomHeaders } from "../Helpers/AxiosCustomHeaders";
import { IDriver } from "../Models/Driver";
import { GridRowId } from "@mui/x-data-grid";

export const createDriverApi = (driver: IDriver) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Driver/CreateDriver`, {
      driverName: driver.driverName,
      driverNumber: driver.driverNumber
    }, { headers: CustomHeaders() });
};

export const getAllDriverApi = (
  search: string | null,
  itemsOnPage: number,
  pageNumber: number) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Driver/GetAllDriver`, {
      search: search,
      itemsOnPage: itemsOnPage,
      pageNumber: pageNumber
    }, { headers: CustomHeaders() });
};

export const removeDriverApi = (driverIds: GridRowId[]) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Driver/RemoveDriver`, { ids: driverIds }, { headers: CustomHeaders() });
};

export const editDriverApi = (driver: IDriver) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Driver/EditDriver`, {
      id: driver.id,
      driverName: driver.driverName,
      driverNumber: driver.driverNumber
    }, { headers: CustomHeaders() });
};