import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridColDef, GridColumnGroupingModel, GridColumnVisibilityModel, GridEventListener, GridFilterModel, GridInputRowSelectionModel, GridRowEditStopReasons, GridRowModel, GridRowModes, GridRowModesModel, GridRowSelectionModel, GridRowsProp } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ReportIcon from '@mui/icons-material/Report';
import { Tooltip } from '@mui/material';

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       {/* <GridToolbarColumnsButton />
//       <GridToolbarFilterButton />
//       <GridToolbarDensitySelector />
//       <GridToolbarExport /> */}
//     </GridToolbarContainer>
//   );
// }

interface EditableGridProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  loading: boolean;
  paginationModel: { pageSize: number; page: number;};
  setPaginationModel: Dispatch<SetStateAction<{ pageSize: number; page: number; }>>;
  columnVisibilityModel: GridColumnVisibilityModel;
  setColumnVisibilityModel: Dispatch<SetStateAction<GridColumnVisibilityModel>>;
  total: number;
  setQueryOptions: (param: GridFilterModel) => void;
  editAction: (newRow: any, oldRow: any) => Promise<boolean>;
  deleteAction: (params: any) => any;
  hideDeleteAction: boolean;
  hideEditAction: boolean;
  hideActionColumn: boolean;
  doubleClickRowAction: (params: any) => any;
  checkboxSelection: boolean;
  disableRowSelectionOnClick: boolean;
  setRowsSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>;
  rowSelectionModel: GridInputRowSelectionModel | undefined;
  hideFooter: boolean;
  hideFooterSelectedRowCount: boolean;
  columnGroupingModel: GridColumnGroupingModel | undefined;
  additionalStyles: React.CSSProperties | undefined;
}
function EditableGrid({rows, columns, editAction, loading, paginationModel, setPaginationModel, total, setQueryOptions, doubleClickRowAction,
  deleteAction, hideDeleteAction, hideEditAction, hideActionColumn, checkboxSelection, disableRowSelectionOnClick, setRowsSelectionModel, rowSelectionModel, hideFooter, hideFooterSelectedRowCount,
  columnVisibilityModel, setColumnVisibilityModel, columnGroupingModel, additionalStyles } : EditableGridProps) {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [promiseArguments, setPromiseArguments] = useState<any>(null);
  const [deleting, setDeleting] = useState<any>(null);
  const [editing, setEditing] = useState<boolean>(false);
  const [onHover, setOnHover] = useState('');
  
  const processRowUpdate = useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        // Save the arguments to resolve or reject the promise later
        setPromiseArguments({ resolve, reject, newRow, oldRow });
      }),
    [],
  );

  const handleSetRowsSelection = useCallback((event: GridRowSelectionModel) => {
    setRowsSelectionModel(event)
  }, [setRowsSelectionModel])
  
  const handleSetPaginationModel = useCallback((event: { pageSize: number; page: number; }) => {
    setPaginationModel(event)
  }, [setPaginationModel])

  const handleDeleteAction = useCallback(async (event: any) => {
    await deleteAction(event)
  }, [deleteAction])
  
  const handleEditAction = useCallback(async (newRow: any, oldRow: any) => {
    return await editAction(newRow, oldRow)
  }, [editAction])

  const handleSetColumnVisibilityModel = useCallback((event: any) => {
    setColumnVisibilityModel(event)
  }, [setColumnVisibilityModel])

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setEditing(false);
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;
    if (await handleEditAction(newRow, oldRow)) {
      resolve(newRow);
      setEditing(false);
    } else {
      //resolve(oldRow); finishing editing and returning to initial values
      reject(newRow); // edit is still active and values are not back to old
    }

    setPromiseArguments(null);
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }
    return (
      <Dialog maxWidth="xs" open={!!promiseArguments}>
        <DialogTitle>Czy na pewno chcesz zmienic dane?</DialogTitle>
        <DialogContent dividers>
          {`Potwierdzenie 'Tak' wykona zmiane.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>Nie</Button>
          <Button onClick={handleYes}>Tak</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleDeleteYes = () => {
    handleDeleteAction(deleting);
    setDeleting(null);
  };

  const renderConfirmDeleteDialog = () => {
    if (!deleting) {
      return null;
    }
    return (
      <Dialog maxWidth="xs" open={!!deleting}>
        <DialogTitle>Czy na pewno chcesz usunąć dane?</DialogTitle>
        <DialogContent dividers>
          {`Potwierdzenie 'Tak' usunie rekord.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleting(null)}>Nie</Button>
          <Button onClick={handleDeleteYes}>Tak</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const onFilterChange = useCallback(async (filterModel: GridFilterModel) => {
    setQueryOptions(filterModel);
  }, [setQueryOptions]);

  const handleRowDoubleClick: GridEventListener<'rowClick'> = (params) => {
    if (!editing) {
      doubleClickRowAction(params.row);
    }
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (row: any) => () => {
    setEditing(true);
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (row: any) => () => {
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (row: any) => () => {
    setDeleting(row);
    handleSetRowsSelection([row.id]);
  };

  const handleCancelClick = (row: any) => () => {
    setEditing(false);
    setRowModesModel({...rowModesModel, [row.id]: { mode: GridRowModes.View, ignoreModifications: true }});
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  
  const actionsColumns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      hideable: false,
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'green',
              }}
              onClick={handleSaveClick(row)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row)}
              sx={{
                color: 'black',
              }}
            />,
          ];
        }

        return [
          (!hideEditAction) ? <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(row)}
            sx={{
              color: 'royalblue',
            }}
          />: <></>,
          (!hideDeleteAction) ? 
            <>
              {(row.approvedBy !== undefined && row.approvedBy !== null && row.approvedBy !== '') ? <ReportIcon sx={{color: onHover, verticalAlign: 'middle', marginRight: '-6px'}} /> : <></>}
              <Tooltip onMouseEnter={() => setOnHover('firebrick')} onMouseLeave={() => setOnHover('')}
              title={row.approvedBy !== undefined && row.approvedBy !== null && row.approvedBy !== '' ? "Usunięcie przychodu zewnętrznego spowoduje usunięcie wszystkich towarów z magazynu i wszystkich jego transformacji." : ''}>
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(row)}
                  sx={{
                    color: 'firebrick',
                  }}/>
              </Tooltip>
            </> : <></>
        ];
      }
    }
  ];
  
  return (
    // MINIMUM WIDTH IS 500 - when lower then additional column at the end is added after click
    <div className='editableGrid' style={additionalStyles}>
      {renderConfirmDialog()}
      {renderConfirmDeleteDialog()}
      <DataGrid
        loading={loading}
        editMode="row"
        rows={rows}
        rowCount={total}
        columns={hideActionColumn ? [...columns] : [...columns, ...actionsColumns]}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handleSetPaginationModel}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onRowDoubleClick={handleRowDoubleClick}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        keepNonExistentRowsSelected={checkboxSelection}
        onCellDoubleClick={(params, event) => {
          if (params.field === 'actions' || params.field === 'barcode' || params.field === 'adminCheck') {
            event.stopPropagation();
          }
          if (!event.ctrlKey) {
            event.defaultMuiPrevented = true;
          }
        }}
        onCellClick={(params, event) => {
          if (params.field === 'barcode') {
            event.stopPropagation();
          }
          if (params.field === 'adminCheck') {
            event.stopPropagation();
            handleSetRowsSelection([params.row.id]);
          }
        }}
        onRowSelectionModelChange={handleSetRowsSelection}
        rowSelectionModel={rowSelectionModel}
        hideFooter={hideFooter}
        hideFooterSelectedRowCount={hideFooterSelectedRowCount}
        getRowClassName={(params) => {
          let rowClass = Number(params.id) > 100000000 ? 'compareCopyRow' : 'compareOriginalRow';
          rowClass += params.indexRelativeToCurrentPage % 2 === 0 ? ' even' : ' odd';
          return rowClass;
          }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => handleSetColumnVisibilityModel(newModel)}
        // slots={{ toolbar: CustomToolbar }}
        //experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        // slotProps={{
        //   pagination: {
        //     showFirstButton: true,
        //     showLastButton: true,
        //   },
        // }}
        onProcessRowUpdateError={(error) => {}}
      />
    </div>
  );
};

export default EditableGrid;