import { Grid, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditableGrid from '../../Features/EditableGrid';
import { IWydanie, IWydanieDetail } from '../../Models/Wydanie';
import { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../../Helpers/AxiosCustomHeaders';
import { AppContext } from '../../App';
import ModalElement from '../../Features/Modal';
import { getAllMagazynToWydanieDetailApi, removeMagazynToWydanieDetailApi } from '../../Services/MagazynToWzDetailService';
import { magazynColumns } from '../../Models/Magazyn';
import { OpenAddConnectionModal } from './OpenAddConnectionModal';
import { LoadingButton } from '@mui/lab';
import UploadIcon from '@mui/icons-material/Upload';
import { ZwrotColumn } from '../AdditionalColumns';
import { createZwrotApi } from '../../Services/WydanieService';
import { enqueueSnackbar } from 'notistack';
import { GridRowSelectionModel } from '@mui/x-data-grid';

interface OpenMagazynToWzDetailModalProps {
	doubleClickedWydanieDetailRow: IWydanieDetail;
	doubleClickedWydanieRow: IWydanie;
  closeMagazynToWzDetailModalAction: Dispatch<void>;
}
export function OpenMagazynToWzDetailModal({ doubleClickedWydanieDetailRow, doubleClickedWydanieRow, closeMagazynToWzDetailModalAction }: OpenMagazynToWzDetailModalProps) {
  const appContext = useContext(AppContext);
	const [total, setTotal] = useState<number>(0);
	const [quantitySum, setQuantitySum] = useState<number>(0);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]); // magazyn(MagazynToWzDetail)
	const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  const [openAddConnectionModal, setOpenAddConnectionModal] = useState(false);
  const [openZwrotModal, setOpenZwrotModal] = useState(false);
  const [rowToZwrot, setRowToZwrot] = useState<any | null>(null);
  const [howManyToZwrot, setHowManyToZwrot] = useState<string>("");
  const [noteZwrot, setNoteZwrot] = useState<string>("");
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const handleCloseMagazynToWzDetailModal = useCallback(() => {
    closeMagazynToWzDetailModalAction();
  }, [closeMagazynToWzDetailModalAction])

  const initialize = useCallback(async () => {
		setLoadingInitialization(true);
		await getAllMagazynToWydanieDetailApi(doubleClickedWydanieDetailRow.id, Number(appContext?.rowsNumberPageModel), page).then(
			(response: AxiosResponse) => {
				setRows(response.data.results);
				setTotal(response.data.total);
				setLoadingInitialization(false);
				setQuantitySum(response.data.quantitySum);
			},
			(error: AxiosError) => {
				handleErros(error, appContext);
				setLoadingInitialization(false);
			});
  }, [doubleClickedWydanieDetailRow.id, page, appContext]);
	useEffect(() => { initialize(); }, [initialize, page]);
	
  const deleteAction = async (row: any) => {
    setLoadingInitialization(true);
    await removeMagazynToWydanieDetailApi(row.id).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingInitialization(false);
        initialize();
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    );
  };

  const closeAddConnectionModalAction = () => {
    setOpenAddConnectionModal(false);
		handleCloseMagazynToWzDetailModal();
		//initialize();
  };

  const closeZwrotModalAction = () => {
		setOpenZwrotModal(false);
		setRowToZwrot(null);
		setHowManyToZwrot("");
		initialize();
  };

  const createZwrotAction = async () => {
		if (rowToZwrot.quantity < Number(howManyToZwrot)) {
      enqueueSnackbar('Nie można zrobić zwrotu wiekszej ilosci niż została wydana.', { variant: 'warning' });
			return;
		}
		if (Number(howManyToZwrot) <= 0) {
      enqueueSnackbar('Nie można zrobić zwrotu kiedy ilość do zwrotu jest równa lub mniejsza niż zero.', { variant: 'warning' });
			return;
		}
		if (noteZwrot === null || noteZwrot === undefined || noteZwrot === "") {
      enqueueSnackbar('Nie można zrobić zwrotu bez uzupełnienia pola z uwagami.', { variant: 'warning' });
			return;
		}

    setLoadingInitialization(true);
    await createZwrotApi(rowToZwrot.id, Number(howManyToZwrot), noteZwrot).then(
      async (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingInitialization(false);
				doubleClickedWydanieDetailRow.count = doubleClickedWydanieDetailRow.count! - Number(howManyToZwrot);
				closeZwrotModalAction();
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    );
  };

	const zwrotModalContent = () => {
    return (<>
      <h1 style={{ margin: '0 0 20px 0' }}>Ile zrobić zwrotu?</h1>
			<span style={{ marginTop: "-28px", marginBottom: "20px" }}>(maximum {rowToZwrot !== null ? rowToZwrot.quantity : ''})</span>
      <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
				style={{marginTop: "-28px", marginBottom: "8px", textAlign: 'center'}}>
				<Grid item xs={12}>
					<TextField
						onChange={(event: { target: { value: string }}) => {setHowManyToZwrot(event.target.value)}}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="number"
						name="number"
						type="number"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label={"Uwagi"}
						onChange={(event: { target: { value: string }}) => {setNoteZwrot(event.target.value)}}
						variant="outlined"
						margin='none'
						required
						fullWidth
						id="note"
						name="note"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
						endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
						loading={loadingInitialization}
						loadingPosition="center"
						variant="contained"
						onClick={createZwrotAction} >
					</LoadingButton>
				</Grid>
      </Grid>
    </>)
  }

	return (
		<><h1 style={{ margin: '0 0 20px 0' }}>Towary pobrane z magazynu</h1>
			{doubleClickedWydanieDetailRow.count !== null && doubleClickedWydanieDetailRow.count! - quantitySum !== 0 &&
				<span style={{ marginTop: "-28px", marginBottom: "20px" }}>(potrzebnych jeszcze {doubleClickedWydanieDetailRow.count! - quantitySum})</span>}
			{doubleClickedWydanieDetailRow.count !== null && doubleClickedWydanieDetailRow.count! - quantitySum !== 0 &&
			<Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1 }} style={{ marginTop: "-28px", marginBottom: "8px" }}>
				{(doubleClickedWydanieRow.completed === false) &&
					<Grid item xs={12} sm={2} md={3} lg={3}>
						<IconButton onClick={() => setOpenAddConnectionModal(true)} disabled={false} className='customAddLoadingButton' color={"primary"}>
							<AddIcon sx={{ fontSize: "30px !important" }} />
						</IconButton>
					</Grid>}
			</Grid>}
			<EditableGrid
				columns={doubleClickedWydanieRow.completed ? magazynColumns.concat(ZwrotColumn(setOpenZwrotModal, setRowToZwrot, true)) : magazynColumns}
				rows={rows}
				editAction={async () => false}
				loading={loadingInitialization}
				setPaginationModel={paginationModelChange}
				paginationModel={{ pageSize: Number(appContext?.rowsNumberPageModel), page: page }}
				total={total}
				setQueryOptions={() => null}
				doubleClickRowAction={() => {}}
				deleteAction={deleteAction}
				hideDeleteAction={false}
				hideEditAction={true}
				hideActionColumn={false}
				checkboxSelection={false}
				disableRowSelectionOnClick={false}
				setRowsSelectionModel={(param: any) => { setRowSelectionModel(param); }}
				rowSelectionModel={rowSelectionModel}
				hideFooter={false}
				hideFooterSelectedRowCount={true}
				columnVisibilityModel={{}}
				setColumnVisibilityModel={() => null}
				columnGroupingModel={undefined}
				additionalStyles={undefined} />
			<ModalElement handleClose={() => { setOpenAddConnectionModal(false); initialize(); }} open={openAddConnectionModal} centerCloseButton={false} additionalStyles={{top: '17% !important'}}
				modalContent={
					<OpenAddConnectionModal doubleClickedWydanieDetailRow={doubleClickedWydanieDetailRow!} quantitySum={quantitySum} closeAddConnectionModalAction={closeAddConnectionModalAction} />
				}
			/>
			<ModalElement handleClose={() => { closeZwrotModalAction() }} open={openZwrotModal} additionalStyles={{top: '10% !important'}} centerCloseButton={false} modalContent={zwrotModalContent()}
			/>
		</>
	);
}