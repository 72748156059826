import { GridColDef } from "@mui/x-data-grid/models";

export interface IDriver {
  id: number | null,
  driverName: string,
  driverNumber: string
}

export const driverColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 60,
    headerAlign: "center",
    align: "center"
  },
  {
    field: 'driverName',
    headerName: 'Kierowca',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 240,
    headerClassName: "alignLeft",
    align: "left"
  },
  {
    field: 'driverNumber',
    headerName: 'Numer auta',
    editable: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 240,
    headerClassName: "alignLeft",
    align: "left"
  }
];