import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import { editWydanieApi } from '../../Services/WydanieService';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../../Helpers/AxiosCustomHeaders';
import { IWydanie } from '../../Models/Wydanie';
import { Debounce } from '../../Helpers/ButtonDebouncing';
import { AppContext } from '../../App';
import { IDriver } from '../../Models/Driver';
import { getAllDriverApi } from '../../Services/DriverService';

const WAIT_INTERVAL = 500;
let timeoutID: number;

interface AdditionalDataWydanieModalProps {
	wydanie: IWydanie;
  setOpenAdditionalDataWydanieModal: Dispatch<SetStateAction<boolean>>;
}
function AdditionalDataWydanieModal({wydanie, setOpenAdditionalDataWydanieModal} : AdditionalDataWydanieModalProps) {
  const appContext = useContext(AppContext);
  const [loadingEditButton, setLoadingEditButton] = useState<boolean>(false);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);


  const [driverName, setDriverName] = useState<string>(wydanie.driverName ?? '');
  const [driverNumber, setDriverNumber] = useState<string>(wydanie.driverNumber ?? '');
  const [euroPalletCount, setEuroPalletCount] = useState<number | null>(wydanie.euroPalletCount);
  const [normalPalletCount, setNormalPalletCount] = useState<number | null>(wydanie.normalPalletCount);

	const [autocompleteTextValue, setAutocompleteTextValue] = useState<string>("");
  const [optionsForDrivers, setOptionsForDrivers] = useState<IDriver[]>([]);
  const [autoCompletePage, setAutoCompletePage] = useState(0);
  const [endOfAutoCompleteOptionsReached, setEndOfAutoCompleteOptionsReached] = useState(false);
	const autoCompleteItemsOnPageLimit = 20;

  const handleSetOpenAdditionalDataWydanieModal = useCallback((event: boolean) => {
    setOpenAdditionalDataWydanieModal(event);
  }, [setOpenAdditionalDataWydanieModal])

	const onDriverInputChange = async (event: any, value: string, reason: any) => {
		if (value) {
			setAutoCompletePage(0);
			setLoadingAutoComplete(true);
			await getAllDriverApi(value, autoCompleteItemsOnPageLimit, 0).then(
				(response: AxiosResponse) => {
					setOptionsForDrivers(response.data.results);
					if (response.data.results.length !== autoCompleteItemsOnPageLimit) {
						setEndOfAutoCompleteOptionsReached(true); }
					else {
						setEndOfAutoCompleteOptionsReached(false);}
					setLoadingAutoComplete(false);
				},
				(error: AxiosError) => {
					handleErros(error, appContext);
					setLoadingAutoComplete(false);
				});
		} else {
			await getAllDrivers();
		}
  };

	const loadMoreDrivers = async (page: number) => {
    setLoadingAutoComplete(true);
		await getAllDriverApi(autocompleteTextValue, autoCompleteItemsOnPageLimit, page).then(
			(response: AxiosResponse) => {
				if (response.data.results.length !== autoCompleteItemsOnPageLimit) {
					setEndOfAutoCompleteOptionsReached(true); }
				else {
					setEndOfAutoCompleteOptionsReached(false);}
				setOptionsForDrivers([...optionsForDrivers, ...response.data.results]);
				setLoadingAutoComplete(false);
			},
			(error: AxiosError) => {
				handleErros(error, appContext);
				setLoadingAutoComplete(false);
			});
  };

	const getAllDrivers = useCallback(async () => {
		setAutoCompletePage(0);
		setEndOfAutoCompleteOptionsReached(false);
		setLoadingAutoComplete(true);
    await getAllDriverApi(null, autoCompleteItemsOnPageLimit, 0).then(
			(response: AxiosResponse) => {
				setOptionsForDrivers(response.data.results);
				setLoadingAutoComplete(false);
			},
			(error: AxiosError) => {
				handleErros(error, appContext);
				setLoadingAutoComplete(false);
			});
  }, [appContext]);
  useEffect(() => { getAllDrivers(); }, [getAllDrivers]);

	const editAction = async () => {
		setLoadingEditButton(true);
		await editWydanieApi(
			wydanie.id,
			wydanie.dostawaDate,
			wydanie.odbiorcaId,
			wydanie.dostawcaNumber,
			wydanie.orderNumber,
			driverName === '' ? null : driverName,
			driverNumber === '' ? null : driverNumber,
			euroPalletCount,
			normalPalletCount)
			.then(
				async (response: AxiosResponse) => {
					wydanie.driverName = driverName === '' ? null : driverName;
					wydanie.driverNumber = driverNumber === '' ? null : driverNumber;
					wydanie.euroPalletCount = euroPalletCount;
					wydanie.normalPalletCount = normalPalletCount;
					await Debounce(setLoadingEditButton, false);
					handleSetOpenAdditionalDataWydanieModal(false);
					handleSuccess(response);
					return true;
				})
			.catch(
				(error: AxiosError) => {
					handleErros(error, appContext);
					return false;
				}
			);
  };

	return (
		<>
			<h1 style={{ margin: '0 0 20px 0' }}>Dodatkowe dane do wydania zewnętrznego</h1>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<Autocomplete
						sx={{mt: '0px', mb: '4px'}}
						options={optionsForDrivers}
						getOptionLabel={(option: IDriver) => { return `${option.driverName} - ${option.driverNumber}`; }}
						isOptionEqualToValue={(option: IDriver, value: IDriver) => option.id === value.id}
						renderOption={(props, option: IDriver) => (
							<li {...props} key={option.id}>
								{`${option.driverName} - ${option.driverNumber}`}
							</li>
						)}
						loading={loadingAutoComplete}
						ListboxProps={{
							onScroll: async (event: React.SyntheticEvent) => {
								if (!endOfAutoCompleteOptionsReached) {
									const listboxNode = event.currentTarget;
									if (
										listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
									) {
										setAutoCompletePage(autoCompletePage + 1);
										await loadMoreDrivers(autoCompletePage + 1);
									}
								}
							},
						}}
						classes={{ paper: "optionsForDrivers" }}
						onChange={async (event: any, value: IDriver | null, reason: any) => {
							if (reason === 'selectOption' && value != null) {
								setDriverName(value.driverName); setDriverNumber(value.driverNumber) }
						}}
						onInputChange={async (event: any, value: string, reason: any) => {
							if (reason === 'input') {
								setAutocompleteTextValue(value);
								window.clearTimeout(timeoutID);
								timeoutID = window.setTimeout(() => {onDriverInputChange(event, value, reason);}, WAIT_INTERVAL);
							}
							if (reason === 'reset' && value === '' || reason === 'clear') {
								setAutocompleteTextValue('');
								await getAllDrivers(); }
						}}
						defaultValue={null}
						renderInput={(params) =>
							<TextField
								{...params}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{loadingAutoComplete ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</>
									),
								}}
								fullWidth
								variant="outlined"
								label={'Wybierz kierowce z listy lub pomiń to pole i wpisz dane kierowcy'}
								autoComplete="cc-number"
								/>
							}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setDriverName(event.target.value)}}
						//defaultValue={wydanie.driverName}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="DriverName"
						name="DriverName"
						label="Kierowca"
						size="medium"
						autoComplete="cc-number"
						value={driverName}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setDriverNumber(event.target.value)}}
						//defaultValue={wydanie.driverNumber}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="DriverNumber"
						name="DriverNumber"
						label="Numer auta"
						size="medium"
						autoComplete="cc-number"
						value={driverNumber}
					/>
				</Grid>
				<Grid item xs={12} >
					<TextField
						sx={{mt: 3}}
						onChange={(event: { target: { value: string }}) => {setEuroPalletCount(event.target.value === null || event.target.value === undefined || event.target.value === '' ? null : Number(event.target.value))}}
						defaultValue={wydanie.euroPalletCount}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="EuroPalletCount"
						name="EuroPalletCount"
						label="Euro palet"
						type="number"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						onChange={(event: { target: { value: string }}) => {setNormalPalletCount(event.target.value === null || event.target.value === undefined || event.target.value === '' ? null : Number(event.target.value))}}
						defaultValue={wydanie.normalPalletCount}
						variant="outlined"
						margin='dense'
						required
						fullWidth
						id="NormalPalletCount"
						name="NormalPalletCount"
						label="Zwykłych palet"
						type="number"
						size="medium"
						autoComplete="cc-number"
					/>
				</Grid>
				<Grid item xs={12}>
					<LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
						endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
						loading={loadingEditButton}
						loadingPosition="center"
						variant="contained"
						onClick={editAction} >
					</LoadingButton>
				</Grid>
			</Grid>
		</>
	)
};

export default AdditionalDataWydanieModal;