import { Dispatch, SetStateAction, useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ClearIcon  from '@mui/icons-material/Clear';
import { pl } from "date-fns/locale/pl";
import { IconButton, InputAdornment } from '@mui/material';

interface DatePickerProps {
  filedName: string;
  setDate: Dispatch<SetStateAction<Date | null>>;
  date: Date | null;
}
function DatePicker({filedName, setDate, date } : DatePickerProps) {
  const handleSetRowsSelection = useCallback((event: Date | null) => {
    setDate(event)
  }, [setDate])
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
      <MobileDatePicker sx={{width: "100%"}}
        label={filedName}
        value={date}
        onChange={(newValue) => handleSetRowsSelection(newValue)}
        closeOnSelect
        slotProps={{
          toolbar: {
            hidden: true,
          },
          actionBar: {
            actions: [],
          },
          textField: {
            InputProps: {
              placeholder: undefined,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={(event) => {
                    event.preventDefault();
                    handleSetRowsSelection(null)
                    }}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;