
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';

export const SteeringWheelCustomIcon = () => {
  return (
    <span
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "Center",
        alignItems: "Center",
        position: 'relative',
        width: '24px',
        height: '24px'
      }}
    >
      <span
        style={{
          position: 'absolute',
          top: '0',
          left: '0'
        }}
      >
        <NightlightRoundIcon sx={{fontSize: "14px", position: 'absolute', color: '#757575', top: '8px', left: '8px', rotate: '45deg'}} />
        <NightlightRoundIcon sx={{fontSize: "14px", position: 'absolute', color: '#757575', top: '8px', left: '2px', rotate: '-225deg'}} />
        <SignalWifi4BarIcon sx={{fontSize: "14px", position: 'absolute', color: '#757575', top: '7px', left: '5px'}} />
        <RadioButtonUncheckedIcon sx={{fontSize: "24px", position: 'absolute', color: '#757575', top: '0px', left: '0px'}} />
      </span>
    </span>
  );
};