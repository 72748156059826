import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ClearIcon  from '@mui/icons-material/Clear';
import { pl } from "date-fns/locale/pl";
import { IdAndLabel } from '../Models/Supply';
import { Autocomplete, Grid, IconButton, InputAdornment, TextField } from '@mui/material';

interface PrzychodyRozchodyRozwagiFilterProps {
	dostawcyOptions: IdAndLabel[];
	loadingAllDostawca: boolean;
  setCurrentDostawcaId: Dispatch<SetStateAction<number | null>>;
  setDateFromValueForSearch: Dispatch<SetStateAction<Date | null>>;
  setDateToValueForSearch: Dispatch<SetStateAction<Date | null>>;
}
export function PrzychodyRozchodyRozwagiFilter({ dostawcyOptions, loadingAllDostawca, setCurrentDostawcaId, setDateFromValueForSearch, setDateToValueForSearch }: PrzychodyRozchodyRozwagiFilterProps) {
  const [dateFromValueForSearch, localSetDateFromValueForSearch] = useState<Date | null>(null);
  const [dateToValueForSearch, localSetDateToValueForSearch] = useState<Date | null>(null);

  const handleSetCurrentDostawcaId = useCallback((param: number | null) => {
    setCurrentDostawcaId(param)
  }, [setCurrentDostawcaId])

  const handleDateFromValueForSearch = useCallback((param: Date | null) => {
		setDateFromValueForSearch(param);
    localSetDateFromValueForSearch(param);
  }, [setDateFromValueForSearch, localSetDateFromValueForSearch])
	
  const handleDateToValueForSearch = useCallback((param: Date | null) => {
		setDateToValueForSearch(param);
    localSetDateToValueForSearch(param);
  }, [setDateToValueForSearch, localSetDateToValueForSearch])
	
  return (
    <>
      <Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
        <Autocomplete
          loading={loadingAllDostawca}
          options={dostawcyOptions}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          renderOption={(props, option: any) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          onChange={(e, data: any) => { if (data != null) {handleSetCurrentDostawcaId(data.id)} else {handleSetCurrentDostawcaId(null)}}}
          defaultValue={null}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Wybierz dostawce"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={3} lg={2} xl={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
          <MobileDatePicker sx={{width: "100%"}}
            label="Utworzony OD"
            value={dateFromValueForSearch}
            onChange={(newValue) => handleDateFromValueForSearch(newValue)}
            closeOnSelect
            slotProps={{
              toolbar: {
                hidden: true,
              },
              actionBar: {
                actions: [],
              },
              textField: {
                InputProps: {
                  placeholder: undefined,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={(event) => {
                        event.preventDefault();
                        handleDateFromValueForSearch(null);
                        }}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              }
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={8} md={3} lg={2} xl={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
          <MobileDatePicker sx={{width: "100%"}}
            label="Utworzony DO"
            value={dateToValueForSearch}
            onChange={(newValue) => handleDateToValueForSearch(newValue)}
            closeOnSelect
            slotProps={{
              toolbar: {
                hidden: true,
              },
              actionBar: {
                actions: [],
              },
              textField: {
                InputProps: {
                  placeholder: undefined,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={(event) => {
                        event.preventDefault();
                        handleDateToValueForSearch(null);
                        }}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              }
            }}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
}