import { styled } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import { forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

interface ModalElementProps {
  open: boolean;
  modalContent: JSX.Element;
  handleClose: () => void;
  additionalStyles: {};
  centerCloseButton: boolean;
}
function ModalElement({modalContent, open, handleClose, additionalStyles, centerCloseButton} : ModalElementProps) {
  return (
    <Modal
      onKeyUp={(event: any) => {if (event.key === "Escape") {handleClose(); event.stopPropagation();}}}
      aria-modal="true"
      // aria-labelledby="transition-modal-title"
      // aria-describedby="transition-modal-description"
      open={open}
      //onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <Fade in={open}>
        <Box sx={[additionalStyles]} className={'modalStyles'}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              transform: centerCloseButton ? 'translate(50%, 0)' : 'none',
              right: centerCloseButton ? '50%' : 3,
              top: 3,
              color: 'gray',
              zIndex: 1
            }}
          >
            <CloseIcon />
          </IconButton>
          {modalContent}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalElement;

const Backdrop = forwardRef<HTMLDivElement, { open?: boolean }>(
  (props, ref) => {
    const { open, ...other } = props;
    return (
      <Fade in={open}>
        <div ref={ref} {...other} />
      </Fade>
    );
  },
);
const StyledBackdrop = styled(Backdrop)`
z-index: -1;
position: fixed;
inset: 0;
background-color: rgb(0 0 0 / 0.5);
-webkit-tap-highlight-color: transparent;
`;

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;