import axios from "axios";
import { CustomHeaders } from "../Helpers/AxiosCustomHeaders";
import { dateToJson } from "../Helpers/DateToJson";
import { ICreateWydanieDetail } from "../Models/Wydanie";

export const createWydanieApi = (
  dostawaDate: Date,
  odbiorcaId: number,
  dostawcaNumber: string,
  orderNumber: string
  ) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/CreateWydanie`, {
      dostawaDate: dateToJson(dostawaDate),
      odbiorcaId: odbiorcaId,
      dostawcaNumber: dostawcaNumber,
      orderNumber: orderNumber
    }, { headers: CustomHeaders() });
};

export const getAllWydanieApi = (
  searchByOdbiorcaId: number | null,
  searchByCreationDateFrom: Date | null,
  searchByCreationDateTo: Date | null,
  searchByDostawaDateFrom: Date | null,
  searchByDostawaDateTo: Date | null,
  searchByNumber: string,
  sortByNumber: boolean,
  sortByDostawaDate: boolean,
  itemsOnPage: number,
  pageNumber: number) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/GetAllWydanie`, {
      searchByOdbiorcaId: searchByOdbiorcaId,
      searchByCreationDateFrom: searchByCreationDateFrom,
      searchByCreationDateTo: searchByCreationDateTo,
      searchByDostawaDateFrom: searchByDostawaDateFrom,
      searchByDostawaDateTo: searchByDostawaDateTo,
      searchByNumber: searchByNumber,
      sortByNumber: sortByNumber,
      sortByDostawaDate: sortByDostawaDate,
      itemsOnPage: itemsOnPage,
      pageNumber: pageNumber
    }, { headers: CustomHeaders() });
};

export const removeWydanieApi = (wydanieId: number) => {
  let query = `wydanieId=${wydanieId}`;
  return axios
    .delete(`${process.env.REACT_APP_API_URL}Wydanie/RemoveWydanie?${query}`, { headers: CustomHeaders() });
};

export const editWydanieApi = (
  id: number,
  dostawaDate: Date,
  odbiorcaId: number,
  dostawcaNumber: string,
  orderNumber: string,
  driverName: string | null,
  driverNumber: string | null,
  euroPalletCount: number | null,
  normalPalletCount: number | null) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/EditWydanie`, {
      id: id,
      dostawaDate: dateToJson(dostawaDate),
      odbiorcaId: odbiorcaId,
      dostawcaNumber: dostawcaNumber,
      orderNumber: orderNumber,
      driverName: driverName,
      driverNumber: driverNumber,
      euroPalletCount: euroPalletCount,
      normalPalletCount: normalPalletCount
    }, { headers: CustomHeaders() });
};

export const completeWydanieApi = (wydanieId: number) => {
  let query = `wydanieId=${wydanieId}`;
  return axios
    .get(`${process.env.REACT_APP_API_URL}Wydanie/CompleteWydanie?${query}`, { headers: CustomHeaders() });
};




export const getAllWydanieDetailsApi = (
  wydanieId: number,
  itemsOnPage: number,
  pageNumber: number) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/GetAllWydanieDetails`, {
      wydanieId: wydanieId,
      itemsOnPage: itemsOnPage,
      pageNumber: pageNumber
    }, { headers: CustomHeaders() });
};

export const createWydanieDetailApi = ( data: ICreateWydanieDetail ) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/CreateWydanieDetail`, data, { headers: CustomHeaders() });
};

export const editWydanieDetailApi = (
  id: number,
  comesFromCountry: string,
  boxType: string,
  numberOfBoxes: number) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/EditWydanieDetail`, {
      id: id,
      comesFromCountry: comesFromCountry,
      boxType: boxType,
      numberOfBoxes: numberOfBoxes
    }, { headers: CustomHeaders() });
};

export const removeWydanieDetailApi = (wydanieDetailId: number) => {
  let query = `wydanieDetailId=${wydanieDetailId}`;
  return axios
    .delete(`${process.env.REACT_APP_API_URL}Wydanie/RemoveWydanieDetail?${query}`, { headers: CustomHeaders() });
};





export const getAllDostawcaForZwrotApi = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}MagazynActions/GetAllDostawcaForZwrot`, { headers: CustomHeaders() });
};

export const getAllZwrotApi = (
  paginationModel: {pageSize: number; page: number;},
  searchByOwnerId: number | null,
  dateFromValueForSearch: Date | null,
  dateToValueForSearch: Date | null) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/GetAllZwrot`, {
      pageNumber: paginationModel.page,
      itemsOnPage: paginationModel.pageSize,
      searchByOwnerId: searchByOwnerId !== null && searchByOwnerId !== undefined ? searchByOwnerId : null,
      searchByCreationDateFrom: dateFromValueForSearch !== null && dateFromValueForSearch !== undefined ? dateToJson(dateFromValueForSearch) : null,
      searchByCreationDateTo: dateToValueForSearch !== null && dateToValueForSearch !== undefined ? dateToJson(dateToValueForSearch) : null
    }, { headers: CustomHeaders() });
};

export const createZwrotApi = (
  magazynToWydanieDetailId: number,
  quantity: number,
  note: string
) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}Wydanie/CreateZwrot`, {
      magazynToWydanieDetailId: magazynToWydanieDetailId,
      quantity: quantity,
      note: note
    }, { headers: CustomHeaders() });
};

export const removeZwrotApi = (zwrotId: number) => {
  let query = `zwrotId=${zwrotId}`;
  return axios
    .delete(`${process.env.REACT_APP_API_URL}Wydanie/RemoveZwrot?${query}`, { headers: CustomHeaders() });
};