import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import EditableGrid from '../Features/EditableGrid';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@mui/material';
import ModalElement from '../Features/Modal';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../Helpers/AxiosCustomHeaders';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { enqueueSnackbar } from 'notistack';
import { Debounce } from '../Helpers/ButtonDebouncing';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { createDriverApi, editDriverApi, getAllDriverApi, removeDriverApi } from '../Services/DriverService';
import { driverColumns, IDriver } from '../Models/Driver';
import Decimal from 'decimal.js';

function DriversPage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState<any[]>([]);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(false);
  const [loadingCreateButton, setLoadingCreateButton] = useState<boolean>(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [deleting, setDeleting] = useState(false);

  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(0);
  const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  
  const [driverName, setDriverName] = useState<string>('');
  const [driverNumber, setDriverNumber] = useState<string>('');

  const handleDeleteSelectedButton = () => {
    setDeleting(true);
  };
  const handleDeleteNo = () => {
    setDeleting(false);
  };
  const handleDeleteYes = async () => {
    await deleteDriversAction(rowSelectionModel.map(s => (s)));
    setRowSelectionModel([]);
    setDeleting(false);
  };
  const renderConfirmDeleteDialog = () => {
    if (!deleting) {
      return null;
    }
    return (
      <Dialog maxWidth="xs" open={!!deleting}>
        <DialogTitle>Czy na pewno chcesz usunąć ({rowSelectionModel.length}) kierowców?</DialogTitle>
        <DialogContent dividers>
          {`Potwierdzenie 'Tak' odpisze towary.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteNo}>Nie</Button>
          <Button onClick={handleDeleteYes}>Tak</Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {setOpenModal(true);};
  const handleCloseModal = () => {setOpenModal(false);}

  const initialize = useCallback(async (pageSize: number, page: number) => {
    setLoadingInitialization(true);
    await getAllDriverApi(null, pageSize, page).then(
      (response: AxiosResponse) => {
        setRows(response.data.results);
        setTotal(response.data.total);
        setLoadingInitialization(false);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      });
  }, [appContext]);
  useEffect(() => { initialize(Number(appContext?.rowsNumberPageModel), page); }, [initialize, page, appContext]);
  
  const deleteSingleDriverAction = async (row: any) => {
    await deleteDriversAction([row.id]);
  };
  
  const deleteDriversAction = async (rows: GridRowId[]) => {
    setLoadingInitialization(true);
    await removeDriverApi(rows).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingInitialization(false);
        setRowSelectionModel([]);
        initialize(Number(appContext?.rowsNumberPageModel), page);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    );
  };

  const addAction = async () => {
    if (driverName === null || driverName === undefined || driverName === '' ||
      driverNumber === null || driverNumber === undefined || driverNumber === '') {
      enqueueSnackbar('Proszę wpisać poprawnie nazwe i numer kierowcy.', { variant: 'info' });
    } else {
      setLoadingCreateButton(true);
      await createDriverApi({ driverName: driverName, driverNumber: driverNumber, id: null }).then(
        async (response: AxiosResponse) => {
          await Debounce(setLoadingCreateButton, false);

          let x = new Decimal(total);
          let y = new Decimal(appContext?.rowsNumberPageModel!);
          setPage(x.add(1).div(y).ceil().minus(1).toNumber());
          //initialize(Number(appContext?.rowsNumberPageModel), page);

          handleCloseModal();
          handleSuccess(response);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingCreateButton(false);
        }
      )
    }
  };
  
  const editAction = async (newRow: IDriver, oldRow: IDriver) : Promise<boolean> => {
    if (newRow.driverName === null || newRow.driverName === undefined || newRow.driverName === '' ||
      newRow.driverNumber === null || newRow.driverNumber === undefined || newRow.driverNumber === '') {
      enqueueSnackbar('Proszę wpisać poprawnie nazwe i numer kierowcy.', { variant: 'info' });
      return false;
    }
    return await editDriverApi(newRow)
      .then(
        (response: AxiosResponse) => {
          initialize(Number(appContext?.rowsNumberPageModel), page);
          handleSuccess(response);
          return true;
        })
      .catch(
        (error: AxiosError) => {
          handleErros(error, appContext);
          return false;
        }
      );
  };

  const modalContentAddDriver = () => {
    return (
      <>
        <h1 style={{ margin: '0 0 20px 0' }}>Dodaj kierowce</h1>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setDriverName(event.target.value)}}
              variant="outlined"
              margin='dense'
              required
              fullWidth
              id="DriverName"
              name="DriverName"
              label="Kierowca"
              size="medium"
              autoComplete="cc-number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setDriverNumber(event.target.value)}}
              variant="outlined"
              margin='dense'
              required
              fullWidth
              id="DriverNumber"
              name="DriverNumber"
              label="Numer auta"
              size="medium"
              autoComplete="cc-number"
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
              endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
              loading={loadingCreateButton}
              loadingPosition="center"
              variant="contained"
              onClick={addAction} >
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    (appContext?.userData?.userRole === UserRole.admin || appContext?.userData?.userRole === UserRole.magazynier) ?
    <><h1>Kierowcy</h1>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
      sx={{marginTop: "-20px !important", marginBottom: {xs: "0px !important", sm: "18px !important"}, textAlign: 'center'}}>
      {rowSelectionModel.length > 0 &&
        <Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'}>
          <LoadingButton sx={{ lineHeight: '33px' }}
            endIcon={<DeleteSweepIcon style={{fontSize: '30px'}}/>}
            loadingPosition="end"
            variant="outlined"
            onClick={handleDeleteSelectedButton}>Usuń zaznaczone
          </LoadingButton>
        </Grid>}
      <Grid item xs={12} sm={1} md={1} lg={1}>
        <IconButton sx={{mt: '3px', mb: '3px'}} onClick={handleOpenModal} >
          <PersonAddIcon />
        </IconButton>
      </Grid>
    </Grid>
    {renderConfirmDeleteDialog()}
    <EditableGrid
      columns={driverColumns}
      rows={rows}
      editAction={editAction}
      loading={loadingInitialization}
      setPaginationModel={paginationModelChange}
      paginationModel={{pageSize: Number(appContext?.rowsNumberPageModel), page: page}}
      total={total}
      setQueryOptions={() => null}
      doubleClickRowAction={() => null}
      deleteAction={deleteSingleDriverAction}
      hideDeleteAction={false}
      hideEditAction={false}
      hideActionColumn={false}
      checkboxSelection={true}
      disableRowSelectionOnClick={false}
      setRowsSelectionModel={(param: any) => { setRowSelectionModel(param); }}
      rowSelectionModel={rowSelectionModel}
      hideFooter={false}
      hideFooterSelectedRowCount={false}
      columnVisibilityModel={{}}
      setColumnVisibilityModel={() => null}
      columnGroupingModel={undefined}
      additionalStyles={undefined}
    />
    <ModalElement handleClose={handleCloseModal} open={openModal} modalContent={modalContentAddDriver()} centerCloseButton={false}
      additionalStyles={{minWidth: '500px !important', maxWidth: '500px !important', top: '10% !important'}}/>
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default DriversPage;