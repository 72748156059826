import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import EditableGrid from '../Features/EditableGrid';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../Helpers/AxiosCustomHeaders';
import { getAllDostawcaForRozwagaApi, getAllRozwagaApi, removeRozwagaApi } from '../Services/MagazynService';
import { actionWithTowarDescriptionColumns } from '../Models/MagazynActions';
import { IdAndLabel } from '../Models/Supply';
import { PrzychodyRozchodyRozwagiFilter } from '../SiteComponents/PrzychodyRozchodyRozwagiFilter';
import { Grid } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingButton } from '@mui/lab';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { getUrlToPrintRozwagaTemplate } from '../Services/SupplyService';

function RozwagiPage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState<any[]>([]);
  const [dostawcyOptions, setDostawcyOptions] = useState<IdAndLabel[]>([]);
  const [loadingAllRozwaga, setLoadingAllRozwaga] = useState<boolean>(false);
  const [loadingAllDostawca, setLoadingAllDostawca] = useState<boolean>(false);
  const [currentDostawcaId, setCurrentDostawcaId] = useState<number | null>(null);
  const [dateFromValueForSearch, setDateFromValueForSearch] = useState<Date | null>(null);
  const [dateToValueForSearch, setDateToValueForSearch] = useState<Date | null>(null);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(0);
  const paginationModelChange = (param: any) => {
    appContext?.setRowsNumberPageModel(param.pageSize);
    setPage(param.page);
  };
  
  const getAllDostawcaForRozwaga = useCallback(async () => {
    setLoadingAllDostawca(true);
    await getAllDostawcaForRozwagaApi().then(
    (response: AxiosResponse) => {
      setDostawcyOptions(response.data);
      setLoadingAllDostawca(false);
    },
    (error: AxiosError) => {
      handleErros(error, appContext);
      setLoadingAllDostawca(false);
    });
  }, [appContext]);

  const getAllRozwaga = useCallback(async () => {
    setLoadingAllRozwaga(true);
    await getAllRozwagaApi({pageSize: Number(appContext?.rowsNumberPageModel), page: page}, currentDostawcaId, dateFromValueForSearch, dateToValueForSearch).then(
    (response: AxiosResponse) => {
      setRows(response.data.results);
      setTotal(response.data.total);
      setLoadingAllRozwaga(false);
    },
    (error: AxiosError) => {
      handleErros(error, appContext);
      setLoadingAllRozwaga(false);
    });
  }, [currentDostawcaId, dateFromValueForSearch, dateToValueForSearch, page, appContext]);

  const initialize = useCallback(async () => {
    getAllDostawcaForRozwaga();
    getAllRozwaga();
  }, [getAllDostawcaForRozwaga, getAllRozwaga]);
  useEffect(() => { initialize(); }, [initialize]);
  
  const deleteRozwagaAction = async (row: any) => {
    setLoadingAllRozwaga(true);
    await removeRozwagaApi(row.id).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingAllRozwaga(false);
        initialize();
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingAllRozwaga(false);
      }
    );
  };

  return (
    (appContext?.userData?.userRole === UserRole.admin || appContext?.userData?.userRole === UserRole.magazynier) ?
    <><h1>Rozwagi</h1>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
      sx={{marginTop: "-10px !important", marginBottom: "20px !important", textAlign: 'center'}}>
      {rowSelectionModel.length > 0 &&
      <Grid item xs={12} sm={12} md={12} lg={'auto'}>
        <LoadingButton sx={{ lineHeight: '33px' }}
          endIcon={<PrintIcon style={{fontSize: '30px'}}/>}
          loadingPosition="end"
          variant="outlined"
          onClick={() => window.open(getUrlToPrintRozwagaTemplate(rowSelectionModel.map(s => (Number(s)))))}>Drukuj zaznaczone
        </LoadingButton>
      </Grid>}
      <PrzychodyRozchodyRozwagiFilter
        dostawcyOptions={dostawcyOptions}
        loadingAllDostawca={loadingAllDostawca}
        setCurrentDostawcaId={setCurrentDostawcaId}
        setDateFromValueForSearch={setDateFromValueForSearch}
        setDateToValueForSearch={setDateToValueForSearch}
      />
    </Grid>
    <EditableGrid
      columns={actionWithTowarDescriptionColumns}
      rows={rows}
      editAction={async () => false}
      loading={loadingAllRozwaga}
      setPaginationModel={paginationModelChange}
      paginationModel={{pageSize: Number(appContext?.rowsNumberPageModel), page: page}}
      total={total}
      setQueryOptions={() => null}
      doubleClickRowAction={() => null}
      deleteAction={deleteRozwagaAction}
      hideDeleteAction={false}
      hideEditAction={true}
      hideActionColumn={false}
      checkboxSelection={true}
      disableRowSelectionOnClick={false}
      setRowsSelectionModel={(param: any) => { setRowSelectionModel(param); }}
      rowSelectionModel={rowSelectionModel}
      hideFooter={false}
      hideFooterSelectedRowCount={false}
      columnVisibilityModel={{}}
      setColumnVisibilityModel={() => null}
      columnGroupingModel={undefined}
      additionalStyles={undefined}
    />
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default RozwagiPage;