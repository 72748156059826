import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import EditableGrid from '../Features/EditableGrid';
import { getDostawcyWithCodeApi } from '../Services/SupplyService';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros } from '../Helpers/AxiosCustomHeaders';
import { producentCodeColumns } from '../Models/ProducentCode';
import { GridRowSelectionModel } from '@mui/x-data-grid';

function ProducentCodePage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState<any[]>([]);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  
  const initialize = useCallback(async () => {
    if (appContext?.userData?.userRole === UserRole.admin || appContext?.userData?.userRole === UserRole.magazynier) {
      setLoadingInitialization(true);
      await getDostawcyWithCodeApi().then(
        (response: AxiosResponse) => {
          setRows(response.data);
          setLoadingInitialization(false);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingInitialization(false);
        });
    }
  }, [appContext?.userData?.userRole]);
  useEffect(() => { initialize(); }, [initialize]);

  return (
    (appContext?.userData?.userRole === UserRole.admin || appContext?.userData?.userRole === UserRole.magazynier) ?
    <><h1>Kod producenta dla dostawcy</h1>
    <EditableGrid
      columns={producentCodeColumns}
      rows={rows}
      editAction={async () => false}
      loading={loadingInitialization}
      setPaginationModel={() => null}
      paginationModel={{pageSize: 100, page: 0}}
      total={0}
      setQueryOptions={() => null}
      doubleClickRowAction={() => null}
      deleteAction={() => null}
      hideDeleteAction={true}
      hideEditAction={true}
      hideActionColumn={true}
      checkboxSelection={false}
      disableRowSelectionOnClick={false}
      setRowsSelectionModel={(param: any) => { setRowSelectionModel(param); }}
      rowSelectionModel={rowSelectionModel}
      hideFooter={true}
      hideFooterSelectedRowCount={true}
      columnVisibilityModel={{}}
      setColumnVisibilityModel={() => null}
      columnGroupingModel={undefined}
      additionalStyles={undefined}
    />
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default ProducentCodePage;