import { IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridOverlay } from "@mui/x-data-grid";
import LineWeightIcon from '@mui/icons-material/LineWeight';
import { getUrlToBarcode, getUrlToPrintPwTemplate } from "../Services/SupplyService";
import PrintIcon from '@mui/icons-material/Print';
import UTurnRightIcon from '@mui/icons-material/UTurnRight';
import { Dispatch, SetStateAction, useCallback, useContext, useRef, useState } from "react";
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import LoadingButton from "@mui/lab/LoadingButton";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Debounce } from "../Helpers/ButtonDebouncing";
import { green, grey } from "@mui/material/colors";
import { changeAdminCheckApi, EntityType } from "../Services/MagazynService";
import { enqueueSnackbar } from "notistack";
import { AxiosResponse, AxiosError } from "axios";
import { handleErros } from "../Helpers/AxiosCustomHeaders";
import { AppContext } from "../App";

export function BarcodeColumn(): GridColDef {
  return {
    field: 'barcode',
    headerName: 'Kod kreskowy',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 140,
    cellClassName: 'barcode',
    headerAlign: "center",
    renderCell: ({ value, row }) => (
      <>
        {value &&
          <Tooltip title="Drukuj kod kreskowy">
            <IconButton onClick={() => window.open(getUrlToBarcode(row.barcode, row.towarName))} disabled={false} className='customAddLoadingButton' color={"primary"} >
              <LineWeightIcon style={{fontSize: '24px', color: 'black', transform: "rotate(90deg)", marginTop: "-1px"}}/>
            </IconButton>
          </Tooltip>}
        {value}
      </>
    )
  };
}

// .concat(PrintPwColumn())
export function PrintPwColumn(): GridColDef {
  return {
    field: 'printColumn',
    headerName: '',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 40,
    cellClassName: 'printColumn',
    headerAlign: "center",
    align: "center",
    renderCell: ({ value, row }) => (
      <Tooltip title="Drukuj">
        <IconButton onClick={() => window.open(getUrlToPrintPwTemplate(row.id))} disabled={false} className='customAddLoadingButton' color={"primary"} >
          <PrintIcon style={{fontSize: '24px'}}/>
        </IconButton>
      </Tooltip>
    )
  };
}

export function ZwrotColumn(openZwrotModalAction: Dispatch<SetStateAction<boolean>> | null, setZwrotRow: Dispatch<SetStateAction<any>> | null, clickable: boolean): GridColDef {
  const handleOpenMagazynToWzDetailModal = useCallback(() => {
    openZwrotModalAction!(true);
  }, [openZwrotModalAction])

  const handleSetZwrotRow = useCallback((row: any) => {
    setZwrotRow!(row);
  }, [setZwrotRow])
  
  const ref = useRef<any>(null);
  const focus = () => {
    ref !== null && ref.current && ref.current.focus()
  }
  
  return {
    field: 'zwrot',
    headerName: '',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 40,
    cellClassName: 'noPadding alignCenter',
    headerAlign: "center",
    renderCell: ({ value, row }) => (
      (clickable ?
        <Tooltip title={row.doneZwrot ? <>Zwrot towaru już zrobiony<br />Usuń zwrot zeby poprawić dane</> :  "Zrób zwrot towaru"} ref={ref}>
          <span>
            <IconButton onClick={() => { handleOpenMagazynToWzDetailModal(); handleSetZwrotRow(row); focus(); }} disabled={row.doneZwrot} className='customAddLoadingButton' color={"primary"} >
              {row.doneZwrot ? 
                <CallMissedOutgoingIcon style={{fontSize: '24px', color: 'black', marginTop: "-1px"}}/> :
                <UTurnRightIcon style={{fontSize: '24px', color: 'black', marginTop: "-1px"}}/>
              }
            </IconButton>
          </span>
        </Tooltip> :
        row.doneZwrot ?
          <IconButton disabled className='customAddLoadingButton' color={"primary"} >
            <CallMissedOutgoingIcon style={{fontSize: '24px', color: 'black', marginTop: "-1px"}}/>
          </IconButton> :
          <></>
      )
    )
  };
}

export function AdminCheckColumn(entityType: EntityType): GridColDef {
  return {
    field: 'adminCheck',
    headerName: '',
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hideable: false,
    width: 40,
    cellClassName: 'noPadding alignCenter',
    headerAlign: "center",
    renderCell: ({ value, row }) => {return AdminCheckCell(value, row, entityType)}
  };
}
function AdminCheckCell(value: any, row: any, entityType: EntityType) {
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);

  const adminCheckClickAction = async (rowId: number, adminCheck: boolean) : Promise<boolean> => {
    if (rowId === null || rowId === undefined || adminCheck === undefined || adminCheck === null) {
      enqueueSnackbar('Dane w wierszu są nie prawidłowe.', { variant: 'error' });
      return false;
    } else {
      return await changeAdminCheckApi(adminCheck, entityType, rowId)
        .then(
          (response: AxiosResponse) => {
            //handleSuccess(response);
            return true;
          })
        .catch(
          (error: AxiosError) => {
            handleErros(error, appContext);
            return false;
          }
        );
    }
  };

  return <LoadingButton className='customAddLoadingButton'
      endIcon={row.adminCheck ?
        <TaskAltIcon sx={{m: '0px', fontSize: "30px !important", color: green[900]}}/> :
        <RadioButtonUncheckedIcon sx={{m: '0px', fontSize: "30px !important", color: grey[300]}}/>}
      loadingPosition="center"
      loading={loading}
      onClick={async () => {
        setLoading(true);
        if (await adminCheckClickAction(row.id, !row.adminCheck)) {
          await Debounce(setLoading, false);
          row.adminCheck = !row.adminCheck;
        } else {
          await Debounce(setLoading, false);
        }}}>
    </LoadingButton>
}